import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdSend, MdMic, MdAttachFile } from 'react-icons/md';

const Footer = props => {

    const [message, setMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (message.trim() !== '') {
            props.onMessage(message);
            setMessage('');
        } else {
            // getMicrophone();
        }
    }

    const sendFile = (f) => {
        const types = ['image/png', 'image/jpeg', 'image/gif'];
        if (!types.every(type => f.type !== type)) {
            props.onFile(f);
        }
    }

    const startRecording = (e) => {
        if (window.ReactNativeWebView && message.trim() === '' && !isRecording) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'startRecording'
            }));
            setIsRecording(true);
        } 
    }

    const stopRecording = (e) => {
        if (window.ReactNativeWebView && message.trim() === '') {
            setTimeout(function() {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: 'stopRecording'
                }));
                setIsRecording(false);
            }, 1000);
        }
    }

    return (
        <>
        { props.suggestions.length > 0 ?
            <div className={`suggestions-row ${props.suggestions.length < 3 ? "suggestions-fix" : ""}`}>
                {
                    props.suggestions.map((s, i) => {
                        return (
                            <button key={i} className="suggestion" onClick={e => props.onMessage(`${s}`)}><div><span>{s}</span></div></button>
                        )
                    })
                }
            </div> : <></>
        }
        <div className="footer-bar">
            <form className="conversation-compose" onSubmit={(e) => submit(e)}>
                <div className="emoji">
                    <label htmlFor="input-file" style={{opacity: "0.25"}}>
                        <MdAttachFile />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley" x="3147" y="3209"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489"/></svg> */}
                    </label>
                    <input id="input-file" accept="image/*,.pdf,.doc,.docx,.log,xls,xlsx" capture style={{display: "none"}} onChange={e => sendFile(e.target.files[0])} type="file"/>
                </div>
                <input className="input-msg" name="input" value={message} onChange={e => setMessage(e.target.value)} placeholder={`${isRecording ? "Gravando..." : "Digite uma mensagem..."}`} autoComplete="off" autoFocus disabled={isRecording}></input>
                <button className="send" onTouchCancel={e => stopRecording(e)} onTouchStart={e => startRecording(e)} onTouchEnd={e => stopRecording(e)}>
                    <div className={`circle ${isRecording ? "red-circle" : ""}`}>
                        {/* <MdSend></MdSend> */}
                        { message.trim() === '' && (window.ReactNativeWebView && window.supportsAudioRecording) ?  <MdMic/> : <MdSend/> }
                    </div>
                  </button>
            </form>
        </div>
        </>
    );
};



Footer.propTypes = {
    onMessage: PropTypes.func.isRequired,
    onFile: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired
};

export default Footer;