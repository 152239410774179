import React from 'react';
import PropTypes from 'prop-types';

const Header = props => {
    return (
        <div className="header-bar">
            <div className="header-photo">
                <img src="./fintalk.png" alt="Avatar"></img>
                {/* <img src="https://cdn.mcauto-images-production.sendgrid.net/ce922ed6b9fc438b/28f58e75-5e86-45e8-bd81-0f2b306fa85a/394x394.gif" alt="Avatar"></img> */}
            </div>
            <div className="header-name"><span>Fintalk</span></div>
            {/* <div className="header-name"><span>{props.name.substring(0,1).toUpperCase()}{props.name.substring(1,props.name.length).toLowerCase()}</span></div> */}
        </div>
    );
};

Header.propTypes = {
    name: PropTypes.string.isRequired
};

export default Header;